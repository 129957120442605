<template>
  <div> 
    <headers />
    <div class="head">
      <img src="@/assets/images/productManualBj.png" />
      <div class="title">产品手册</div>
      <div class="searchBox">
        <search @search="searchFun" :type="2" />
      </div>
      <!-- <div class="title2">
        发现下载安装新版本所需的所有项目内容，
        每个发布版本都将以软件包的形式提供下载。
      </div> -->
    </div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料获取</el-breadcrumb-item>
        <el-breadcrumb-item>产品手册</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content">
      <div class="leftBox">
        <div class="menuBox" v-for="(item, index) in menuList" :key="index">
          <div class="menuItem" @click="actMenuOne(item)">
            <div class="menuItemL">{{ item.name }}</div>
            <div class="menuItemR">
              <i
                :style="actOneMeun == item.id ? 'color:#00D8C3' : ''"
                :class="
                  actOneMeun == item.id
                    ? 'el-icon-arrow-up'
                    : 'el-icon-arrow-right'
                "
              ></i>
            </div>
          </div>

          <div class="menuCellBox" v-if="actOneMeun == item.id">
            <div
              class="menuCellItem"
              v-for="(items, indexs) in item.children"
              @click="changeItem(items)"
              :key="indexs"
              :style="actId == items.id ? 'color:#00D8C3' : ''"
            >
              {{ items.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <!-- <div class="searchBox">
          <search @search="searchFun" />
        </div> -->
        <template v-if="list.length">
          <div class="card">
            <div class="cardItem" v-for="(item, index) in list" :key="index">
              <div class="imgBox">
                <img class="hoverTransform" :src="item.image" />
              </div>

              <div class="title textOverflowOne">
                {{ item.name }}
              </div>

              <div class="titleDetails textOverflowTwo">
                {{ item.productDesc }}
              </div>

              <div class="btnBox">
                <div>浏览：{{ item.readNum }}</div>
                <el-button @click="toDetails(item)" class="searchBoxR"
                  >查看资料</el-button
                >
              </div>
            </div>
          </div>
          <div class="page">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pages.total"
              :page-size="pages.size"
              :current-page="pages.page"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </template>
        <None v-else />
      </div>
    </div>
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { productClassifyList, productList } from "@/api/lx";
import ToolDetails from "./toolDetails.vue";

export default {
  name: "productManual",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      actOneMeun: 0,
      actId: "",
      menuList: [],
      searchValue: "",
      pages: {
        page: 1,
        total: 0,
        size: 12,
      },
      list: [],
    };
  },
  created() {
    this.getProductClassifyList();
  },
  mounted() {},
  methods: {
    searchFun(val) {
      this.searchValue = val;
      this.pages.page = 1;
      this.getList();
    },
    currentChange(val) {
      this.pages.page = val;
      this.getList();
    },
    getProductClassifyList() {
      productClassifyList({}).then((res) => {
        this.menuList = res.data;
        if (this.menuList.length > 0) {
          this.actId = this.menuList[0].children[0].id;
          this.actOneMeun = this.menuList[0].id;
          this.getList();
        }
      });
    },
    getList() {
      productList({
        page: this.pages.page,
        size: this.pages.size,
        name: this.searchValue,
        classifyId: this.actId,
      }).then((res) => {
        this.list = res.data.records;
        this.pages.total = res.data.total;
      });
    },
    changeItem(item) {
      this.actId = item.id;
      this.pages.page = 1;
      this.getList();
    },
    actMenuOne(val) {
      if (this.actOneMeun == val.id) {
        this.actOneMeun = "";
      } else {
        this.actOneMeun = val.id;
      }
    },
    toDetails(item) {
      this.$router.push({
        path: "/information/productManualDetails",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 10;
    width: 100%;
    text-align: center;
    margin-top: 60px;
  }
  .title2 {
    width: 400px;
    height: 56px;
    margin: 0 auto;
    text-align: center;
    margin-top: 35px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    line-height: 28px;
    font-style: normal;
    position: relative;
    z-index: 10;
  }
}
.content {
  width: 100%;
  display: flex;
  margin-top: 0px;
}
.leftBox {
  width: 477px;
  .menuBox {
    width: 393px;
    margin-left: 45px;
    padding: 15px 30px 15px 0px;
    box-sizing: border-box;
    border-top: 1px solid #e6edf0;
    cursor: pointer;
    .menuItem {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      color: #00161a;
    }
    .menuCellBox {
      border-top: 1px solid #e6edf0;
      padding-top: 15px;
      margin-top: 15px;
      padding-left: 40px;
      box-sizing: border-box;
      :first-child {
        margin-top: 0 !important;
      }
      .menuCellItem {
        font-size: 16px;
        color: #00161a;
        line-height: 22px;
        margin-top: 15px;
      }
    }
  }
}
.rightBox {
  width: calc(100vw - 477px);
  margin-top: 15px;
}
.searchBox {
  margin-bottom: 20px;
}
.card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .cardItem {
    width: 330px;
    margin-right: 26px;
    margin-bottom: 26px;
    .imgBox {
      width: 330px;
      height: 330px;
      border: 1px dashed #e6edf0;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-weight: 600;
      font-size: 18px;
      color: #00161a;
      margin-top: 15px;
    }
    .titleDetails {
      font-weight: 400;
      font-size: 14px;
      height: 40px;
      line-height: 20px;
      color: #525f66;
      text-align: left;
      margin-top: 10px;
    }
    .btnBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      div {
        font-weight: 400;
        font-size: 14px;
        color: #525f66;
      }
      .searchBoxR {
        width: 104px;
        height: 39px;
        background: #00d8c3;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        &.is-active,
        &:active {
          border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
          outline: none; /* 移除默认的轮廓线 */
        }
      }
    }
  }
}
.page {
  margin-left: 233px;
  margin-bottom: 55px;
}
.searchBox {
  margin-top: 54px;
}


</style>